import _ from 'lodash';

const currentEnvironment = (origin, environment) => (
  _.includes(global.location.origin, origin) && environment
);

export const environment = () => _.reduce([
  ['local.dir.dev.manage.rackspace.com', 'local'],
  ['dir.dev.manage.rackspace.com', 'dev'],
  ['dir.manage.rackspace.com', 'prod'],
  ['localhost', 'test']
], (result, args) => result || currentEnvironment(...args), null) || 'prod';

export const dev = () => ({
  dirAwsAccountNumber: '413973659835',
  dirAzureServicePrincipalId: '3e047cd7-3da2-4370-8ef6-204d3eccda08',
  dirGcpServiceAccount: 'automation@rackspace-management-dev.iam.gserviceaccount.com'
});
export const prod = () => ({
  dirAwsAccountNumber: '261078805363',
  dirAzureServicePrincipalId: '3e047cd7-3da2-4370-8ef6-204d3eccda08',
  dirGcpServiceAccount: 'automation@rackspace-management.iam.gserviceaccount.com'
});
export const local = () => dev();
export const test = () => local();
export const environments = () => ({ test, local, dev, prod });
export const all = () => _.get(environments(), environment(), () => ({}))();
export const get = (...args) => _.get(all(), ...args);
