import './logo.css';

import React from 'react';
import * as particles from '@janus.team/janus-particles';
import * as router from 'react-router-dom';
import * as session from '../session/index.js';

const UtilityNavigationLink = (props) => (
  <particles.Navigation.Link utility as={router.NavLink} activeClassName="particles-navigation-link--utility-active" {...props} />
);

export const UtilityNavigation = () => {
  const { name, username } = session.useUser();
  return (
    <header className="janus-dir-header">
      <particles.Navigation.Utility>
        <particles.Navigation.Logo as={router.NavLink} to="/">
          Janus
        </particles.Navigation.Logo>
        <particles.Navigation.Menu utility>
          <UtilityNavigationLink to={`/accounts/all`}>
            All Accounts
          </UtilityNavigationLink>
          <UtilityNavigationLink to={`/accounts/aws`}>
            AWS
          </UtilityNavigationLink>
          <UtilityNavigationLink to={`/accounts/azure`}>
            Azure
          </UtilityNavigationLink>
          <UtilityNavigationLink to={`/accounts/gcp`}>
            GCP
          </UtilityNavigationLink>
        </particles.Navigation.Menu>
        <particles.Navigation.Menu utility right>
          <particles.Navigation.Text>
            {name} ({username})
          </particles.Navigation.Text>
        </particles.Navigation.Menu>
      </particles.Navigation.Utility>
    </header>
  );
};

export const Footer = () => (
  <particles.Navigation footer>
    <particles.Navigation.Utility>
      <particles.Navigation.Menu utility>
        <particles.Navigation.Text>&copy; {new Date().getFullYear()} Rackspace US, Inc.</particles.Navigation.Text>
        <particles.Navigation.Link footer
          href="http://www.rackspace.com/information/legal/privacystatement"
          target="_blank"
        >
          Privacy Policy
        </particles.Navigation.Link>
      </particles.Navigation.Menu>
    </particles.Navigation.Utility>
  </particles.Navigation>
);

