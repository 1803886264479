import React from 'react';
import * as particles from '@janus.team/janus-particles';
import * as router from 'react-router-dom';
import * as navigation from './navigation.js';
import * as accounts from './accounts/index.js';
import * as session from '../session/index.js';

import texas from '../icons/texas.svg';

const SplashScreen = () => (
  <div className="placeholder">
    <h1>Texas DIR</h1>
    <img src={texas} alt="Texas DIR" />
    <span>
      <i className="fa fa-circle-o-notch fa-spin" />{' '}
      Loading...
    </span>
  </div>
);

const Main = () => (
  <main className="janus-dir-main-content">
    <router.Switch>
      <router.Route path="/accounts/:cloud">
        <accounts.List />
      </router.Route>
      <router.Redirect to={`/accounts/all`} />
    </router.Switch>
  </main>
);

const particlesConfiguration = () => ({
  componentMaturity: {
    component: {
      AlertBar: 'none',
      ButtonGroup: 'none',
      Modal: 'none',
      Popover: 'none',
      Tooltip: 'none'
    }
  }
});
export const App = () => (
  <particles.configuration.ConfigurationProvider {...particlesConfiguration()}>
    <session.SessionProvider>
      <particles.Page>
        <session.Unauthenticated>
          <SplashScreen />
        </session.Unauthenticated>
        <session.Authenticated>
          <navigation.UtilityNavigation />
          <Main />
          <navigation.Footer />
        </session.Authenticated>
      </particles.Page>
    </session.SessionProvider>
  </particles.configuration.ConfigurationProvider>
);

export const AppWithRouter = () => (
  <router.BrowserRouter><App /></router.BrowserRouter>
);
