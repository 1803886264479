import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'font-awesome/css/font-awesome.css';
import 'react-tippy/dist/tippy.css';
import '@janus.team/janus-particles/dist/particles.css';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { AppWithRouter } from './app/index.js';

ReactDOM.render(
  <React.StrictMode>
    <AppWithRouter />
  </React.StrictMode>,
  document.getElementById('root')
);
