import _ from 'lodash';
import { environment } from './environment.js';
import cookies from 'js-cookie';

const defaultFetchOptions = () => ({
  headers: {
    'x-csrf-token': cookies.get(`__tx_dir_csurf_${environment()}__`),
    'User-Agent': 'janus-dir-frontend',
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  credentials: (/local|rax\.io/i).test(global.location.origin) ? 'include' : 'same-origin'
});
const readAndPreserveBody = (res) => res.text().then(text => {
  res.janus = res.janus || {};
  res.janus.text = text;
  try {
    res.janus.json = JSON.parse(text);
  } catch (e) {
    res.janus.json = {};
  }
  res.text = () => Promise.resolve(res.janus.text);
  res.json = () => Promise.resolve(res.janus.json);
  return res;
});

const redirectTo = () => global.encodeURIComponent([
  global.location.pathname,
  global.location.search
].join(''));

const handle403 = (res) => {
  if ((res.status !== 403) || !(/invalidcsrftoken/i).test(_.get(res, 'janus.json.error.code'))) {
    return Promise.resolve(res);
  }
  return Promise.reject(_.merge(new Error('Invalid CSRF Token'), {
    res,
    name: 'InvalidCSRFToken',
    mustRedirect: `/api/go?to=${redirectTo()}`
  }));
};
const handle401 = (res) => {
  if ((res.status !== 401)) {
    return Promise.resolve(res);
  }
  return Promise.reject(_.merge(new Error('Unauthenticated'), {
    res,
    name: 'Unauthenticated',
    mustRedirect: `/api/login?RelayState=${redirectTo()}`
  }));
};

const handleError = (error) => {
  const { mustRedirect } = error || {};
  if (mustRedirect) {
    global.location.replace(mustRedirect);
  }
  return Promise.reject(error);
};

const defaultConfiguration = () => ({
  handleError,
  handle401,
  handle403,
  fetch: global.fetch
});

export const fetch = (url, customFetchOptions, customConfiguration) => {
  const configuration = _.merge(
    defaultConfiguration(),
    customConfiguration
  );
  const fetchOptions = _.merge(
    defaultFetchOptions(),
    customFetchOptions
  );
  const { fetch, handle403, handle401, handleError } = configuration;
  return fetch(url, fetchOptions)
    .then(readAndPreserveBody)
    .then(handle401)
    .then(handle403)
    .catch(handleError);
};
