import React from 'react';
import _ from 'lodash';

export const useIndexedResource = (index) => React.useState({
  [index]: { loading: false, cached: false, error: null, data: null }
});
export const markAsLoading = (index, set) => set(state => _.merge({}, state, {
  [index]: { loading: true }
}));
export const markAsCached = (index, set, data) => set(state => _.merge({}, state, {
  [index]: { loading: false, data, error: null, cached: true }
}));
export const markAsError = (index, set, error) => set(state => _.merge({}, state, {
  [index]: { loading: false, error }
}));
export const select = (state, index, ...rest) => _.get(state, [index, ...rest]);
