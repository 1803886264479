import _ from 'lodash';
import React from 'react';
import * as session from '../session/index.js';
import * as particles from '@janus.team/janus-particles';

export const usePermissions = (action) => {
  const { roles } = session.useUser();
  const permissions = session.usePermissions();
  const requiredRoles = _.get(permissions, [action], []);
  const canPerform = _.some(requiredRoles, requiredRole => (
    _.some(roles, userRole => (
      _.toLower(userRole) === _.toLower(requiredRole)
    ))
  ));
  const hookValue = { canPerform, requiredRoles, roles, permissions };
  return hookValue;
};
export const RequiresRoleTooltip = ({ action, children }) => {
  const permission = usePermissions(action);
  const render = _.isFunction(children) ? children : () => children;
  const tooltipContent = () => (
    <div>
      <p>
        Requires at least one of the following roles:
      </p>
      <ul>
        {_.map(permission.requiredRoles, role => (
          <li key={role}><code>{role}</code></li>
        ))}
      </ul>
    </div>
  );
  return permission.canPerform ? (
    render({ permission })
  ) : (
    <particles.Tooltip content={tooltipContent()} arrow>
      <span>
        {render({ permission })}
      </span>
    </particles.Tooltip>
  );
};
