import _ from 'lodash';
import * as resource from '../../resource.js';
import * as http from '../../http.js';

const checkStatus = (res) => res.ok ? Promise.resolve(res) : Promise.reject(new Error(`HTTP ${res.status}`));
const parseResponse = _.property('accounts');
const filterByCloud = (cloud) => (accounts) => (
  (cloud === 'all') ? accounts : _.filter(accounts, { cloud })
);

export const fetchAccounts = (cloud, set) => {
  resource.markAsLoading(cloud, set);
  const query = _.get({
    aws: '?cloud=aws',
    gcp: '?cloud=gcp',
    azure: '?cloud=azure'
  }, cloud) || '';
  return http.fetch(`/api/accounts${query}`)
    .then(checkStatus)
    .then(_.method('json'))
    .then(parseResponse)
    .then(filterByCloud(cloud))
    .then(data => resource.markAsCached(cloud, set, data))
    .catch(error => resource.markAsError(cloud, set, error));
};
