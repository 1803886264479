import React from 'react';

import * as particles from '@janus.team/janus-particles';

export const AccountDetailsSection = ({ account, title, size }) => (
  <particles.Page.MainBodySection title={title ?? 'Account Details'}>
    <particles.KeyValuePair size={size || 'medium'}>
      <span>Account Name</span>
      <span>{account?.name}</span>
    </particles.KeyValuePair>
    <particles.KeyValuePair size={size || 'medium'}>
      <span>Account ID</span>
      <span>{account?.id}</span>
    </particles.KeyValuePair>
  </particles.Page.MainBodySection>
);
