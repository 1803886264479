import React from 'react';
import _ from 'lodash';
import ClipboardButton from 'react-clipboard.js';

import * as particles from '@janus.team/janus-particles';

export const focusNextCopyButton = (button) => {
  const allButtons = global.document.querySelectorAll('[data-clipboard-text]');
  const existingIndex = _.indexOf(allButtons, button?.trigger);
  const target = _.get(allButtons, existingIndex + 1) || _.head(allButtons);
  _.invoke(target, 'focus');
};

const copyButtonOnClick = (button) => {
  if (_.get(button, 'trigger.childNodes[0]')) {
    button.trigger.childNodes[0].className = 'fa fa-check-circle success';
    setTimeout(() => button.trigger.childNodes[0].className = 'fa fa-clipboard', 2000);
  }

  if (_.get(button, 'trigger.childNodes[1]')) {
    focusNextCopyButton(button);
    button.trigger.childNodes[1].innerText = 'Copied!';
    setTimeout(() => button.trigger.childNodes[1].innerText = 'Copy', 2000);
  }
};

export const KeyValuePairWithCopy = ({ label, children, value, copyValue, size }) => (
  <particles.KeyValuePair size={size ?? 'medium'}>
    <span>{label}</span>
    <span>{value ?? children}</span>
    <span>
      <particles.Button as={ClipboardButton} tertiary type="button" data-clipboard-text={copyValue ?? value} onSuccess={copyButtonOnClick}>
        <i className="fa fa-clipboard" /><span>Copy</span>
      </particles.Button>
    </span>
  </particles.KeyValuePair>
);
