import _ from 'lodash';
import React from 'react';
import * as router from 'react-router-dom';

import * as particles from '@janus.team/janus-particles';

import * as events from '../../events.js';
import * as context from '../../context.js';
import * as resource from '../../resource.js';
import { fetchAccounts } from './data.js';
import * as onboard from './onboard.js';
import * as credentials from './credentials.js';
import * as permissions from '../../permissions/index.js';
import * as configureAccess from './configure-access.js';
import * as loadingElement from '../../components/loading.js';

const useGlobalKeyboardShortcut = () => {
  events.useEventListener('keydown', ({ ctrlKey, metaKey, key }) => {
    if ((key === '/') && (ctrlKey || metaKey)) {
      _.invoke(global.document.querySelector('input[name="searchCriteria"]'), 'focus');
    }
  }, global.document.body);
};
const placeholder = `Search [ctrl + /]...`;
const MainActions  = () => {
  const { searchCriteria, setSearchCriteria, setOnboardModalState, accounts, cloud } = React.useContext(context.accounts);
  const loading = resource.select(accounts, cloud, 'loading') && resource.select(accounts, cloud, 'cached');
  useGlobalKeyboardShortcut();
  return (
    <particles.Collection compact>
      {loading ? (<i className="fa fa-circle-o-notch fa-spin"/>) : null}
      <particles.Form name="search" size="small" onSubmit={_.method('preventDefault')}>
        <particles.Form.Input name="searchCriteria" placeholder={placeholder} value={searchCriteria} onChange={({ target: { value } }) => setSearchCriteria(value)} />
      </particles.Form>
      <permissions.RequiresRoleTooltip action="accounts.create">
        {({ permission: { canPerform } }) => (
          <particles.Button primary type="button" onClick={() => setOnboardModalState((state) => _.merge({}, state, { visible: true }))} disabled={!canPerform}>
            Onboard Account
          </particles.Button>
        )}
      </permissions.RequiresRoleTooltip>
    </particles.Collection>
  );
};

const searchBy = (accounts, searchCriteria) => (
  _.filter(accounts, (account) => _.some(_.values(account),
    (value) => ~_.toLower(`${value}`).indexOf(_.toLower(_.trim(searchCriteria)))
  ))
);
const Status = ({ status }) => {
  const color = {
    'waiting_for_access': 'pink',
    'onboarding': 'yellow',
    'active': 'green'
  }[_.toLower(status)];
  const filled = {
    'waiting_for_access': true,
    'onboarding': false,
    'active': false
  }[_.toLower(status)];
  return (
    <particles.Pill color={color} filled={!!filled}>{_.startCase(_.toLower(status))}</particles.Pill>
  );
};

const TableInlineButton = ({ as, ...rest }) => {
  const props = as ? { type: 'button' } : {};
  return (
    <particles.Button as={as} {...props} {...rest} tertiary />
  );
};
const ConsoleButton = ({ account }) => {
  const consoleBaseUrl = (account.partition === 'government') ?
    'https://signin.amazonaws-us-gov.com' :
    'https://signin.aws.amazon.com';

  const consoleRedirectUrl = (account.partition === 'government') ?
    'amazonaws-us-gov.com' :
    'aws.amazon.com';

  const onClickAws = (e) => {
    e.preventDefault();
    const href = e.target.href;
    const image = new Image();
    const consoleWindow = window.open('', href);
    image.onerror = err => consoleWindow.location = href;
    image.src = `${consoleBaseUrl}/oauth?Action=logout&redirect_uri=${consoleRedirectUrl}`;
  };

  const onClick = _.get({
    'aws': onClickAws
  }, account.cloud, _.noop);

  return (
    <permissions.RequiresRoleTooltip action="accounts.console">
      {({ permission: { canPerform } }) => (
        <TableInlineButton as="a" target="_blank" href={`/api/accounts/${account.id}/console`} disabled={account.cloud !== 'aws' || !canPerform} onClick={onClick}>
          Console
        </TableInlineButton>
      )}
    </permissions.RequiresRoleTooltip>
  );
};

const ConfigureAccessButton = (props) => (
  <configureAccess.ConfigureAccessButton as={TableInlineButton} {...props} />
);

const TemporaryCredentialsButton = ({ account, setCredentialsModalState }) => {
  const onClick = () => setCredentialsModalState((state) => _.merge({}, state, { visible: true, account }));
  return (
    <permissions.RequiresRoleTooltip action="accounts.console">
      {({ permission: { canPerform } }) => (
        <TableInlineButton onClick={onClick} disabled={account.cloud !== 'aws' || !canPerform}>
          Temporary Credentials
        </TableInlineButton>
      )}
    </permissions.RequiresRoleTooltip>
  );
};

const caseInsensitiveSort = (accounts, key, order = 'asc') => _.orderBy(accounts, [(e) => _.lowerCase(_.get(e, [key]))], [order]);
const flipOrder = (sort) => sort.order === 'asc' ? 'desc': 'asc';
const sortIcon = (sort) => sort.order === 'asc' ? <i className="fa fa-sort-up" /> : <i className="fa fa-sort-down" />;

const WaitingForAccessActions = (props) => (
  <particles.Collection compact right>
    <ConfigureAccessButton {...props} />
  </particles.Collection>
);
const OnboardingActions = (props) => (
  <particles.Collection compact right>
    <ConsoleButton {...props} />
    <TemporaryCredentialsButton {...props} />
  </particles.Collection>
);
const ActiveActions = (props) => (
  <particles.Collection compact right>
    <ConsoleButton {...props} />
    <TemporaryCredentialsButton {...props} />
  </particles.Collection>
);
const AccountActions = ({ account, ...rest }) => {
  const AccountActionsComponentForStatus = _.get({
    WAITING_FOR_ACCESS: WaitingForAccessActions,
    ACTIVE: ActiveActions,
    ONBOARDING: OnboardingActions
  }, account?.status) || (() => null);
  return (<AccountActionsComponentForStatus account={account} {...rest} />);
};

const Table = () => {
  const { accounts, searchCriteria, cloud, setCredentialsModalState, fetchAccounts, setAccounts } = React.useContext(context.accounts);
  const [sort, setSort] = React.useState({ key: 'name', order: 'asc' });

  return (
    <particles.Table compact className="janus-dir-account-list-table">
      <particles.Table.Header>
        <particles.Table.Row>
          <particles.Table.Cell compact header>{/* Cloud */}</particles.Table.Cell>
          <particles.Table.Cell compact header onClick={() => setSort({ key: 'name', order: flipOrder(sort)})}>
            Account {sort.key === 'name' && sortIcon(sort)}
          </particles.Table.Cell>
          <particles.Table.Cell compact header onClick={() => setSort({ key: 'id', order: flipOrder(sort)})}>
            Id {sort.key === 'id' && sortIcon(sort)}
          </particles.Table.Cell>
          <particles.Table.Cell compact header onClick={() => setSort({ key: 'status', order: flipOrder(sort)})}>
            Status {sort.key === 'status' && sortIcon(sort)}
          </particles.Table.Cell>
          <particles.Table.Cell compact header>{/* Links */}</particles.Table.Cell>
        </particles.Table.Row>
      </particles.Table.Header>
      <particles.Table.Body>
        {_.map(caseInsensitiveSort(searchBy(resource.select(accounts, cloud, 'data'), searchCriteria), sort.key, sort.order), (account) => (
          <particles.Table.Row key={account.id}>
            <particles.Table.Cell compact>
              <span className={`janus-dir-account-list-cloud-icon janus-dir-account-list-cloud-icon--${account.cloud}`} title={account.cloud} />
            </particles.Table.Cell>
            <particles.Table.Cell compact>
              {account.name}
            </particles.Table.Cell>
            <particles.Table.Cell compact>
              {account.id}
            </particles.Table.Cell>
            <particles.Table.Cell compact>
              <Status status={account.status} />
            </particles.Table.Cell>
            <particles.Table.Cell compact>
              <AccountActions {...{ account, setCredentialsModalState, fetchAccounts, setAccounts, cloud }}  />
            </particles.Table.Cell>
          </particles.Table.Row>
        ))}
      </particles.Table.Body>
    </particles.Table>
  );
};


export const List = () => {
  const { cloud } = router.useParams();
  const onboardingTargetCloud = (cloud === 'all') ? 'aws' : cloud;
  const [onboardModalState, setOnboardModalState] = React.useState({ visible: false, selectedCloud: onboardingTargetCloud, onSuccess: () => fetchAccounts(cloud, setAccounts) });
  const [credentialsModalState, setCredentialsModalState] = React.useState({ visible: false, selectedPlatform: 'linux' });
  const [accounts, setAccounts] = resource.useIndexedResource(cloud);
  const [searchCriteria, setSearchCriteria] = React.useState('');
  const cloudTitle = { aws: 'AWS', gcp: 'GCP' };
  const title = `${cloudTitle[cloud] || _.startCase(cloud)} Accounts`;

  React.useEffect(() => {
    fetchAccounts(cloud, setAccounts);
  }, [cloud, setAccounts]);

  React.useEffect(() => {
    const pollAccounts = setInterval(() => {
      fetchAccounts(cloud, setAccounts);
    }, 30000);
    return () => clearInterval(pollAccounts);
  }, [cloud, setAccounts]);

  const cached = resource.select(accounts, cloud, 'cached');
  const empty = !_.some(resource.select(accounts, cloud, 'data'));
  const error = resource.select(accounts, cloud, 'error');

  const contents = _.reduce([
    [cached && empty, () => (<p>There are no Accounts.</p>)],
    [cached, () => (<Table />)],
    [error, () => (<particles.AlertBar level="error">Something Went Wrong</particles.AlertBar>)],
    [true, () => (<loadingElement.Block text={`Loading ${title}...`} />)]
  ], (result, [criteria, render]) => result || (criteria && render()), null);

  const contextValue = {
    setOnboardModalState,
    onboardModalState,
    setCredentialsModalState,
    credentialsModalState,
    searchCriteria,
    setSearchCriteria,
    accounts,
    fetchAccounts,
    setAccounts,
    cloud
  };

  return (
    <context.accounts.Provider value={{...contextValue}}>
      <particles.Page.Main>
        <particles.Page.MainHeader title={title} action={<MainActions />} />
        <particles.Page.MainBodySection>
          {contents}
        </particles.Page.MainBodySection>
        <onboard.OnboardAccount />
        {credentialsModalState.visible ? <credentials.TemporaryCredentials /> : null}
      </particles.Page.Main>
    </context.accounts.Provider>
  );
};
