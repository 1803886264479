import _ from 'lodash';
import React from 'react';

import * as router from 'react-router-dom';

import * as particles from '@janus.team/janus-particles';
import * as permissions from '../../permissions/index.js';
import * as http from '../../http.js';
import * as resource from '../../resource.js';
import * as environment from '../../environment.js';
import { focusNextCopyButton, KeyValuePairWithCopy } from '../../components/key-value-pair-with-copy.js';
import { AccountDetailsSection } from './account-details-section.js';

const createTrustRelationship = ({ account }, ...stringifyArgs) => JSON.stringify({
  Version: '2012-10-17',
  Statement: [{
    Sid: '',
    Effect: 'Allow',
    Principal: {
      AWS: `arn:aws:iam::${environment.get('dirAwsAccountNumber')}:root`
    },
    Action: 'sts:AssumeRole',
    Condition: {
      StringEquals: {
        'sts:ExternalId': `${account?.externalId}`
      }
    }
  }]
}, ...stringifyArgs);

const preventDefault = (fn) => (event) => {
  _.invoke(event, 'preventDefault');
  _.invoke(event, 'stopPropagation');
  return fn(event);
};

export const ValidateAccessButton = ({ account, validated, setValidated, cloud, fetchAccounts, setAccounts, close }) => {
  const loading = resource.select(validated, account.id, 'loading');

  const onClick = preventDefault(() => {
    resource.markAsLoading(account.id, setValidated);
    http.fetch(`/api/accounts/${account.id}/validate`, {
      method: 'POST'
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error.message);
        });
      }
      return res.json();
    }).then(({ data }) => fetchAccounts(cloud, setAccounts).then(() => {
      resource.markAsCached(account.id, setValidated, data);
      close();
    })).catch((error) => {
      resource.markAsError(account.id, setValidated, error);
    });
  });
  const isValidating = !!loading;
  return (
    <permissions.RequiresRoleTooltip action="accounts.create">
      {({ permission: { canPerform } }) => {
        const disabled = isValidating || !canPerform;
        return (
          <particles.Button type="button" {...{ loading, disabled, onClick }} primary>
            Validate
          </particles.Button>
        );
      }}
    </permissions.RequiresRoleTooltip>
  );
};

const TrustRelationship = ({ account }) => {
  const [expanded, setExpanded] = React.useState(false);
  const expandedTrustRelationship = createTrustRelationship({ account }, null, '  ');
  const collapsedTrustRelationship = _.truncate(
    createTrustRelationship({ account }),
    { length: 40, omission: '...' }
  );
  React.useLayoutEffect(() => {
    setTimeout(focusNextCopyButton, 10);
  }, []);
  return (
    <div className="trust-relationship">
      {expanded ? (
        <div>
          <particles.Button type="button" tertiary onClick={() => setExpanded(false)}>
            Collapse{'  '}
            <i className="fa fa-chevron-up collapse-icon" />
          </particles.Button>
          <pre>
            <code>{expandedTrustRelationship}</code>
          </pre>
        </div>
      ) : (
        <div>
          <pre onClick={() => setExpanded(true)}>
            <code>{collapsedTrustRelationship}</code>
          </pre>
        </div>
      )}
    </div>
  );
};

const ModalFooter = ({ close, validated, account, ...rest }) => {
  const error = !resource.select(validated, account?.id, 'loading') && (resource.select(validated, account?.id, 'error'));
  return (
    <particles.Modal.Footer>
      {error && <particles.AlertBar level="error">{error.message}</particles.AlertBar>}
      <particles.Collection compact>
        <ValidateAccessButton {...{ close, account, validated }} {...rest} />
        <particles.Button tertiary type="button" onClick={close}>Cancel</particles.Button>
      </particles.Collection>
    </particles.Modal.Footer>
  );
};

export const ConfigureAccessModalForGcp = ({ close, account, ...rest }) => {
  const action = (
    <particles.Link target="_blank" href="https://cloud.google.com/sdk/gcloud/reference/projects/add-iam-policy-binding">
      Add IAM Policy Binding (gcloud CLI) {' '}<i className="fa fa-external-link"/>
    </particles.Link>
  );
  const gcloudCliCommand = `gcloud projects add-iam-policy-binding \\\n   ${account.id} \\\n   --role roles/owner \\\n   --member serviceAccount:${environment.get('dirGcpServiceAccount')}`;
  return (
    <particles.Modal onClose={close} size="medium">
      <particles.Modal.Header title="Configure Access for GCP" />
      <particles.Modal.Body>
        <particles.Page.MainBodySection title="Target GCP Project">
          <particles.KeyValuePair size="medium">
            <span>Project Name</span>
            <span>{account?.name}</span>
          </particles.KeyValuePair>
          <particles.KeyValuePair size="medium">
            <span>Project ID</span>
            <span>{account?.id}</span>
          </particles.KeyValuePair>
        </particles.Page.MainBodySection>
        <particles.Page.MainBodySection title="IAM Policy Binding" action={action}>
          <particles.KeyValuePair size="medium">
            <span>Role</span>
            <span>roles/owner</span>
          </particles.KeyValuePair>
          <particles.KeyValuePair size="medium">
            <span>Service Account</span>
            <span>{environment.get('dirGcpServiceAccount')}</span>
          </particles.KeyValuePair>
          <KeyValuePairWithCopy label="gcloud CLI Command" copyValue={gcloudCliCommand}>
            <span className="gcloud-cli-command"><pre><code>{gcloudCliCommand}</code></pre></span>
          </KeyValuePairWithCopy>
        </particles.Page.MainBodySection>
      </particles.Modal.Body>
      <ModalFooter {...{ close, account }} {...rest} />
    </particles.Modal>
  );
};

export const ConfigureAccessModalForAzure = ({ close, account, ...rest }) => {
  const action = (
    <particles.Link target="_blank" href="https://docs.microsoft.com/en-us/azure/role-based-access-control/role-assignments-cli">
      Create Role Assignment (Azure CLI) {' '}<i className="fa fa-external-link"/>
    </particles.Link>
  );
  const cliCommand = `az role assignment create \\\n   --subscription "${account.id}" \\\n   --role "Owner" \\\n   --assignee "${environment.get('dirAzureServicePrincipalId')}"`;
  return (
    <particles.Modal onClose={close} size="medium">
      <particles.Modal.Header title="Configure Access for Azure" />
      <particles.Modal.Body>
        <particles.Page.MainBodySection title="Target Azure Subscription">
          <particles.KeyValuePair size="medium">
            <span>Subscription Name</span>
            <span>{account?.name}</span>
          </particles.KeyValuePair>
          <particles.KeyValuePair size="medium">
            <span>Subscription ID</span>
            <span>{account?.id}</span>
          </particles.KeyValuePair>
        </particles.Page.MainBodySection>
        <particles.Page.MainBodySection title="Role Assignment" action={action}>
        <particles.KeyValuePair size="medium">
            <span>Role</span>
            <span>Owner</span>
          </particles.KeyValuePair>
          <particles.KeyValuePair size="medium">
            <span>Service Principal</span>
            <span>Rackspace Management</span>
          </particles.KeyValuePair>
          <KeyValuePairWithCopy label="Azure CLI Command" copyValue={cliCommand}>
            <span className="azure-cli-command"><pre><code>{cliCommand}</code></pre></span>
          </KeyValuePairWithCopy>
        </particles.Page.MainBodySection>
      </particles.Modal.Body>
      <ModalFooter {...{ close, account }} {...rest} />
    </particles.Modal>
  );
};

export const ConfigureAccessModalForAws = ({ close, account, cloud, setAccounts, fetchAccounts, validated, setValidated }) => {
  const trustRelationship = createTrustRelationship({ account }, null, '  ');
  const roleName = 'RackspaceManagement';
  const iamPolicy = 'AdministratorAccess';
  const action = (
    <particles.Link target="_blank" href="https://console.aws.amazon.com/iam/home?region=us-west-2#/roles$new?step=type&roleType=crossAccount">
      Create Role (AWS Console) {' '}<i className="fa fa-external-link"/>
    </particles.Link>
  );
  return (
    <particles.Modal onClose={close} size="medium">
      <particles.Modal.Header title="Configure Access for AWS" />
      <particles.Modal.Body>
        <AccountDetailsSection {...{ account, title: 'Target AWS Account' }} />
        <particles.Page.MainBodySection title="Trust Relationship" action={action}>
          <KeyValuePairWithCopy label="Trusted Account ID" value={environment.get('dirAwsAccountNumber')} />
          <KeyValuePairWithCopy label="External ID" value={account?.externalId} />
          <KeyValuePairWithCopy label="Role Name" value={roleName} />
          <KeyValuePairWithCopy label="IAM Policy" value={iamPolicy} />
          <KeyValuePairWithCopy label="Trust Relationship" copyValue={trustRelationship}>
            <TrustRelationship account={account} />
          </KeyValuePairWithCopy>
        </particles.Page.MainBodySection>
      </particles.Modal.Body>
      <ModalFooter {...{ account, validated, setValidated, cloud, setAccounts, fetchAccounts, close }} />
    </particles.Modal>
  );
};

export const ConfigureAccessModal = ({ account, ...rest }) => {
  const [validated, setValidated] = resource.useIndexedResource(account?.id);
  const Modal = _.get({
    gcp: ConfigureAccessModalForGcp,
    azure: ConfigureAccessModalForAzure
  }, account?.cloud) || ConfigureAccessModalForAws;
  return (<Modal {...{ account, validated, setValidated }} {...rest} />);
};

export const ConfigureAccessButton = ({ account, as, cloud, setAccounts, fetchAccounts }) => {
  const path = `/accounts/${cloud}/${account?.cloud}_${account?.id}/access-configuration`;
  const isOpen = router.useRouteMatch({ path, strict: true, sensitive: true });
  const history = router.useHistory();
  const open = preventDefault(() => {
    history.push(path);
  });
  const close = preventDefault(() => {
    history.replace(`/accounts/${cloud}`);
  });
  const Button = as ?? particles.Button;
  return (
    <React.Fragment>
      {isOpen && (<ConfigureAccessModal {...{ isOpen, close, account, setAccounts, fetchAccounts }} />)}
      <permissions.RequiresRoleTooltip action="accounts.create">
        {({ permission: { canPerform } }) => (
          <Button disabled={!canPerform} onClick={open}>
            Configure Access
          </Button>
        )}
      </permissions.RequiresRoleTooltip>
    </React.Fragment>
  );
};


