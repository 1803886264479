import _ from 'lodash';
import React from 'react';
import ClipboardButton from 'react-clipboard.js';

import * as particles from '@janus.team/janus-particles';

import * as http from '../../http.js';
import * as loadingElement from '../../components/loading.js';
import * as context from '../../context.js';
import * as resource from '../../resource.js';

const credentialsAsLinuxShell = (credentials) => (
`# Copy-paste into your terminal to use temporary credentials.
export AWS_ACCESS_KEY_ID="${credentials.accessKeyId}"
export AWS_SECRET_ACCESS_KEY="${credentials.secretAccessKey}"
export AWS_SESSION_TOKEN="${credentials.sessionToken}"
`);

const credentialsAsPowershell = (credentials) => (
`# Copy-paste into your terminal to use temporary credentials.
$env:AWS_ACCESS_KEY_ID="${credentials.accessKeyId}"
$env:AWS_SECRET_ACCESS_KEY="${credentials.secretAccessKey}"
$env:AWS_SESSION_TOKEN="${credentials.sessionToken}"

Set-AWSCredentials -AccessKey $env:AWS_ACCESS_KEY_ID -SecretKey $env:AWS_SECRET_ACCESS_KEY -SessionToken $env:AWS_SESSION_TOKEN
`);

const credentialsAsWindows = (credentials) => (
`REM Copy-paste into your terminal to use temporary credentials.
SET AWS_ACCESS_KEY_ID="${credentials.accessKeyId}"
SET AWS_SECRET_ACCESS_KEY="${credentials.secretAccessKey}"
SET AWS_SESSION_TOKEN="${credentials.sessionToken}"
`);

const credentialsAsAwsCredentialsFile = (credentials) => (
`# Add this profile to your aws credentials file to use temporary credentials.
[default]
aws_access_key_id = ${credentials.accessKeyId}
aws_secret_access_key = ${credentials.secretAccessKey}
aws_session_token = ${credentials.sessionToken}
`);

const platformCredentialsFormatter = {
  'linux': credentialsAsLinuxShell,
  'powershell': credentialsAsPowershell,
  'windows': credentialsAsWindows,
  '~/.aws/credentials': credentialsAsAwsCredentialsFile
};

const copyButtonOnClick = (button) => {
  if (_.get(button, 'trigger.childNodes[0]')) {
    button.trigger.childNodes[0].className = 'fa fa-check-circle success';
    setTimeout(() => button.trigger.childNodes[0].className = 'fa fa-clipboard', 2000);
  }

  if (_.get(button, 'trigger.childNodes[1]')) {
    button.trigger.childNodes[1].innerText = 'Copied!';
    setTimeout(() => button.trigger.childNodes[1].innerText = 'Copy', 2000);
  }
};

const fetchCredentials = (account, setCredentials) => () => {
  resource.markAsLoading(account.id, setCredentials);
  http.fetch(`/api/accounts/${account.id}/credentials`, {
    method: 'POST'
  }).then((res) => {
    if (!res.ok) {
      return res.json().then(({ error }) => {
        throw new Error(error.message);
      });
    }
    return res.json();
  }).then(({ credentials }) => {
    resource.markAsCached(account.id, setCredentials, credentials);
  }).catch((error) => {
    resource.markAsError(account.id, setCredentials, error);
  });
};

export const TemporaryCredentials = () => {
  const { credentialsModalState, setCredentialsModalState } = React.useContext(context.accounts);
  const { visible, selectedPlatform, account } = credentialsModalState || {};

  const [credentials, setCredentials] = resource.useIndexedResource(account.id);
  React.useEffect(fetchCredentials(account, setCredentials), [account.id]);

  const cached = resource.select(credentials, account.id, 'cached');
  const loading = resource.select(credentials, account.id, 'loading');
  const error = resource.select(credentials, account.id, 'error');
  const accountCredentials = resource.select(credentials, account.id, 'data');

  const close = () => setCredentialsModalState((state) => _.merge({}, state, { visible: false }));
  const selectPlatform = (platform) => () => setCredentialsModalState((state) => _.merge({}, state, { selectedPlatform: platform }));
  const formatter = platformCredentialsFormatter[selectedPlatform];
  return visible ? (
    <particles.Modal onClose={close} size="medium">
        <particles.Modal.Header title="Temporary Credentials" />
        {loading && <particles.Modal.Body><loadingElement.Block text="Creating Temporary Credentials..."/></particles.Modal.Body>}
        {error && <particles.Modal.Body><particles.AlertBar level="error">Something Went Wrong: {error.message}</particles.AlertBar></particles.Modal.Body>}
        {cached && <>
        <particles.Modal.Body>
          <particles.ButtonGroup className="temporary-credentials-type-selector">
            <particles.Button primary={selectedPlatform === 'linux'} type="button" onClick={selectPlatform('linux')}>
              Linux
            </particles.Button>
            <particles.Button primary={selectedPlatform === 'powershell'} type="button" onClick={selectPlatform('powershell')}>
              PowerShell
            </particles.Button>
            <particles.Button primary={selectedPlatform === 'windows'} type="button" onClick={selectPlatform('windows')}>
              Windows
            </particles.Button>
            <particles.Button primary={selectedPlatform === '~/.aws/credentials'} type="button" onClick={selectPlatform('~/.aws/credentials')}>
              ~/.aws/credentials
            </particles.Button>
          </particles.ButtonGroup>
          <particles.Form.Input type="textarea" rows="10" disabled value={formatter(accountCredentials)} name="temporaryCredentials" />
        </particles.Modal.Body>
        <particles.Modal.Footer>
          <particles.Collection compact>
            <particles.Button as={ClipboardButton} secondary type="submit" data-clipboard-text={formatter(accountCredentials)} onSuccess={copyButtonOnClick}>
            <i className="fa fa-clipboard" /><span>Copy</span>
            </particles.Button>
          </particles.Collection>
        </particles.Modal.Footer>
        </>}
    </particles.Modal>
  ) : null;
};
