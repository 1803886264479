import _ from 'lodash';
import React from 'react';

import * as particles from '@janus.team/janus-particles';

import * as context from '../../context.js';
import * as http from '../../http.js';

const formContext = React.createContext({});

const useFieldProps = (name) => {
  const { fields, setFields } = React.useContext(formContext);
  const value = _.get(fields, [name, 'value'], '');
  const onChange = ({ target: { value } }) => {
    setFields((state) => _.merge({}, state, { [name]: { value: value } }));
  };
  return { value, onChange, name };
};
const FormForAzure = () => {
  const azurePartition = useFieldProps('azurePartition');
  const azureFriendlyName = useFieldProps('azureFriendlyName');
  const azureIdentifier = useFieldProps('azureIdentifier');
  return (
    <React.Fragment>
      <particles.Form.Input label="Partition" type="select" {...azurePartition}>
        <option value="public">Public Cloud</option>
        <option value="government">Government Cloud</option>
      </particles.Form.Input>
      <particles.Form.Input label="Name" {...azureFriendlyName} />
      <particles.Form.Input label="Subscription ID" {...azureIdentifier} />
    </React.Fragment>
  );
};
const FormForAws = () => {
  const awsPartition = useFieldProps('awsPartition');
  const awsFriendlyName = useFieldProps('awsFriendlyName');
  const awsIdentifier = useFieldProps('awsIdentifier');
  return (
    <React.Fragment>
      <particles.Form.Input label="Partition" type="select" {...awsPartition}>
        <option value="public">Public Cloud</option>
        <option value="government">Government Cloud</option>
      </particles.Form.Input>
      <particles.Form.Input label="Name" {...awsFriendlyName} />
      <particles.Form.Input label="AWS Account Number" {...awsIdentifier} />
    </React.Fragment>
  );
};
const FormForGcp = () => {
  const gcpFriendlyName = useFieldProps('gcpFriendlyName');
  const gcpIdentifier = useFieldProps('gcpIdentifier');
  return (
    <React.Fragment>
      <particles.Form.Input label="Name" {...gcpFriendlyName} />
      <particles.Form.Input label="Project ID" {...gcpIdentifier} />
    </React.Fragment>
  );
};

const OnboardingForm = () => {
  const { onboardModalState } = React.useContext(context.accounts);
  const { selectedCloud } = onboardModalState || {};
  const FormComponent = _.get({
    azure: FormForAzure,
    gcp: FormForGcp
  }, selectedCloud) || FormForAws;
  return (
    <FormComponent />
  );
};

const useFormBody = (formContextValue) => {
  const { fields } = formContextValue;
  const { onboardModalState } = React.useContext(context.accounts);
  const { selectedCloud } = onboardModalState || {};
  const targetFields = _.get({
    gcp: { name: 'gcpFriendlyName', id: 'gcpIdentifier' },
    azure: { name: 'azureFriendlyName', id: 'azureIdentifier', partition: 'azurePartition' }
  }, selectedCloud) || { name: 'awsFriendlyName', id: 'awsIdentifier', partition: 'awsPartition' };
  return {
    account: _.reduce(targetFields, (account, formField, bodyField) => ({
      ...account,
      [bodyField]: _.trim(_.get(fields, [formField, 'value'])) || null
    }), { cloud: selectedCloud })
  };
};
const initialFields = () => ({
  awsPartition: { value: 'public' },
  azurePartition: { value: 'public' }
});
const useFormProps = (formContextValue) => {
  const { onboardModalState, setOnboardModalState } = React.useContext(context.accounts);
  const { onSuccess } = onboardModalState;
  const { setFields, setFormState } = formContextValue;
  const close = () => setOnboardModalState((state) => _.merge({}, state, { visible: false }));
  const formBody = useFormBody(formContextValue);
  const onSubmit = (event) => {
    _.invoke(event, 'preventDefault');
    setFormState((state) => _.merge({}, state, { submitting: true }));
    http.fetch('/api/accounts', {
      method: 'POST',
      body: JSON.stringify(formBody)
    }).then(() => {
      setFormState((state) => _.merge({}, state, { submitting: false }));
      setFields(initialFields());
      onSuccess();
      close();
    }).catch((error) => {
      setFormState((state) => _.merge({}, state, { submitting: false, error }));
    });
  };
  return { onSubmit };
};

export const OnboardAccount = () => {
  const { onboardModalState, setOnboardModalState } = React.useContext(context.accounts);
  const { selectedCloud, visible } = onboardModalState || {};
  const [formState, setFormState] = React.useState({ submitting: false });
  const [fields, setFields] = React.useState(initialFields());
  const formContextValue = { formState, setFormState, fields, setFields };
  const formProps = useFormProps(formContextValue);
  const close = () => setOnboardModalState((state) => _.merge({}, state, { visible: !!formState.submitting }));
  const selectCloud = (cloud) => () => setOnboardModalState((state) => _.merge({}, state, { selectedCloud: cloud }));
  return visible ? (
    <particles.Modal onClose={close} size="small">
      <formContext.Provider value={{...formContextValue}}>
        <particles.Modal.Header title="Onboard New Account" />
        <particles.Form name="onboardAccount" size="unbound" {...formProps}>
          <particles.Modal.Body>
            <particles.ButtonGroup>
              <particles.Button primary={selectedCloud === 'aws'} type="button" onClick={selectCloud('aws')}>
                AWS
              </particles.Button>
              <particles.Button primary={selectedCloud === 'azure'} type="button" onClick={selectCloud('azure')}>
                Azure
              </particles.Button>
              <particles.Button primary={selectedCloud === 'gcp'} type="button" onClick={selectCloud('gcp')}>
                GCP
              </particles.Button>
            </particles.ButtonGroup>
            <OnboardingForm />
          </particles.Modal.Body>
          <particles.Modal.Footer>
            <particles.Collection compact>
              <particles.Button primary type="submit" loading={!!formState.submitting}>
                Onboard
              </particles.Button>
              <particles.Button tertiary type="button" disabled={!!formState.submitting} onClick={close}>
                Cancel
              </particles.Button>
            </particles.Collection>
          </particles.Modal.Footer>
        </particles.Form>
      </formContext.Provider>
    </particles.Modal>
  ) : null;
};
