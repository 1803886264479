import _ from 'lodash';
import React from 'react';

import * as resource from '../resource.js';
import * as http from '../http.js';
import * as context from '../context.js';

const useSessionResource = (index) => {
  const [store, set] = resource.useIndexedResource(index);
  React.useEffect(() => {
    resource.markAsLoading(index, set);
    http.fetch('/api/session').then(_.method('json')).then(
      (data) => resource.markAsCached(index, set, data),
      (error) => resource.markAsError(index, set, error)
    );
  }, [index, set]);
  return [store, set];
};
export const useSessionContext = () => React.useContext(context.session) || ({
  session: { name: null, username: null },
  permissions: {},
  ready: false
});
export const useSession = () => {
  const { ready, session } = useSessionContext() || {};
  return { ready, session };
};
export const useUser = () => useSession()?.session?.user || {};
export const usePermissions = () => useSessionContext()?.permissions || {};
export const SessionProvider = ({ children }) => {
  const [store] = useSessionResource('root');
  const ready = !!resource.select(store, 'root', 'cached');
  const data = resource.select(store, 'root', 'data') || {};
  const contextValue = { ...data, ready };
  return (
    <context.session.Provider value={{...contextValue}}>
      {children}
    </context.session.Provider>
  );
};
export const Authenticated = ({ children }) => {
  const { ready, session } = useSession() || {};
  const render = _.isFunction(children) ? children : () => children;
  return ready ? render(session) : null;
};
export const Unauthenticated = ({ children }) => {
  const { ready, session } = useSession() || {};
  const render = _.isFunction(children) ? children : () => children;
  return ready ? null : render(session);
};

